<template>
<div class="bidmain">
  <div class="pageDrtails">
    <h1 class="pageDrtails-title">{{pageDrtails.title}}</h1>
    <div v-html="pageDrtails.content" ></div>
  </div>
  <div class="countDownMain">
    <div class="h1" ref="h1">招标截止倒计时</div>
    <div class="section">
      <span ref="day"></span>天
      <span ref="hour"></span>时
      <span ref="minute"></span>分
      <span ref="second"></span>秒
    </div>
    
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "biddtail",
  data() {
    return {
      pageDrtails: {
        title: "",
        content: "",
        enddata:''
      },
    };
  },
  created() {
    this.$store.state.navIndex = "/bidding";
  },
  mounted() {
    //进入编辑页的标识
    sessionStorage.setItem("detailB", true);
     //进入编辑页的标识（关键字）
    sessionStorage.setItem("ifInputB", true);
    axios
      .get("/api/bulletin/view/" + this.$route.query.id)
      .then(
        (res) => {
          let page = res.data.content;
          this.pageDrtails.title = page.title;
          this.pageDrtails.content = page.content;
          this.enddata = res.data.content.endTime
        },
        () => {
          this.$message({
            message: "网络好像出现点问题",
            type: "error",
            showClose: true,
            offset: 70,
          });
        }
      );
    let day = this.$refs['day']
    let hour = this.$refs['hour']
    let minute = this.$refs['minute']
    let second = this.$refs['second']
    let h1 = this.$refs['h1']
    setInterval(
      ()=>{
        let data = new Date()
        let enddata = new Date(this.enddata)
        let remainTime = (enddata.getTime()-data.getTime())/1000
        if (remainTime<=0){
          h1.innerHTML = '招标已经截止'
          day.innerHTML = 0
          hour.innerHTML = 0
          minute.innerHTML = 0
          second.innerHTML = 0
        }else{
          day.innerHTML = parseInt(remainTime/86400)
          hour.innerHTML = parseInt(remainTime%86400/3600)
          minute.innerHTML = parseInt(remainTime%86400%3600/60)
          second.innerHTML = parseInt(remainTime%86400%3600%60)
        }
      },
      1000
    )
  },
};
</script>

<style scoped>
.bidmain{
  position: relative;
}
.pageDrtails {
  width: 80%;
  margin: 0 auto;
}
.pageDrtails-title {
  font-size: 28px;
  text-align: center;
}
.Countdown{
  position: absolute;
  right: -80px;
  top:0;
  border:3px solid red ;
  width: 100px;
  height: 100px;

}
  .countDownMain{
    position: fixed;   
    top: 11rem;
    right: 0px;
    /* border: 1.5px solid black; */
    background: rgba(240, 240, 240, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 13.5rem;
  }
  .countDownMain .h1{
    text-align: center;
    width: 100%;
    font-size: 1.3rem;
    padding-bottom: 0;
    margin-bottom: 0px;
    color:#0e0611;
  }
  .section{
    color:rgb(219, 77, 77);
    font-size: 1.2rem;
    margin-top: 1.4rem;
    display: flex;
    justify-content: center;
    line-height: 1.3rem;
  }
  .section span{
    color: #ff0000;
    font-size: 1.9rem;
  }


/* ul ol 样式 */
::v-deep ol {
  margin: 10px 0 10px 22px;
  list-style-type: decimal;
}
::v-deep ul {
  margin: 10px 0 10px 22px;
  list-style-type: disc;
}
/* table 样式 */
::v-deep table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
::v-deep table td,
::v-deep table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
  height: 32px;
}
::v-deep table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
  background-color: #f1f1f1;
}

/* blockquote 样式 */
::v-deep blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  min-height: 24px;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
::v-deep code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
::v-deep pre code {
  display: block;
}
::v-deep a {
  text-decoration: underline;
  color: #5592e5;
}


</style>